import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import store from '../../store'

Vue.use(Vuex)

export default function Api () {
  return {
    loading (state) {
      store.commit('loading', state)
    },
    apiBase () {
      return process.env.VUE_APP_WEB_API_URL
    },
    throwError (error) {
      if ([403, 404].indexOf(error.response.status) > -1) {
        store.commit('token', null)
        if (this.$router) {
          this.$router.push('/login').catch(() => {})
        }
      } else {
        this.loading(false)
        throw error
      }
    },
    getAuthHeader () {
      return {
        headers: {
          'Authorization': `Token ${store.state.token}`
        }
      }
    },
    login (user, password) {
      this.loading(true)

      const data = {
        username: user,
        password: password
      }

      return axios
        .post(
          process.env.VUE_APP_WEB_URL + 'api-auth-token/',
          data
        )
        .then(response => {
          this.loading(false)
          store.commit('token', response.data.token)
          return response
        })
        .catch(error => {
          this.loading(false)
          throw error
        })
    },
    zones () {
      this.loading(true)
      return axios
        .get(
          this.apiBase() + 'zones/', this.getAuthHeader()
        )
        .then(response => {
          this.loading(false)
          return response
        })
        .catch(error => {
          this.throwError(error)
        })
        .finally(this.loading(false))
    },
    set_cam_controls (jsonData) {
      return axios
        .post(
          this.apiBase() +
          'cams/' +
          jsonData.cam_id +
          '/change_cam_controls/',
          jsonData,
          this.getAuthHeader()
        )
        .catch(error => {
          this.loading(false)
          throw error
        })
    },
    cameras_accesses (startDateFilter = null, endDateFilter = null) {
      if (startDateFilter && endDateFilter) {
        return axios
          .get(
            process.env.VUE_APP_WEB_URL + 'cameras_accesses/?start_date=' + startDateFilter + '&end_date=' + endDateFilter, this.getAuthHeader()
          )
          .catch(error => {
            this.loading(false)
            throw error
          })
      } else {
        return axios
          .get(
            process.env.VUE_APP_WEB_URL + 'cameras_accesses/', this.getAuthHeader()
          )
          .catch(error => {
            this.loading(false)
            throw error
          })
      }
    }
  }
}
