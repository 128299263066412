<template>
  <div v-if="users_camera_accesses[0]" class="content" id="statistics_page">
    <h1 :hidden="!apiError">Estadísticas de uso</h1>
    <div>
      <div id="filter_date">
        <div id="start_date_filter">
          <label for="start">Fecha Inicio Filtrado:</label>
          <input type="date" id="start" v-model="start_date" >
        </div>
        <div id="end_date_filter">
          <label for="end">Fecha Fin filtrado:</label>
          <input type="date" id="end" v-model="end_date" >
        </div>
        <button v-on:click="filter_by_date">
          Filtrar
        </button>

      </div>
      <table id="statistics_table">
        <tbody>
          <tr>
            <td></td>
            <td v-for='(camera_access, index) in users_camera_accesses[0].camera_accesses' :key="index">
              {{ camera_access.camera.name}}
            </td>
          </tr>
          <tr v-for='(user_camera_accesses, index) in users_camera_accesses' :key="index">
            <td>
              {{ user_camera_accesses.user.username }}
            </td>
            <td v-for='(user_camera_access, index) in user_camera_accesses.camera_accesses' :key="index">
              {{ user_camera_access.camera_access_time}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="api_error_div" :hidden="apiError">Ha ocurrido un error inesperado</div>
  </div>
</template>
<script>
export default {
  components: {},
  name: 'StatisticsScreen',
  data () {
    return {
      users_camera_accesses: [],
      apiError: true,
      start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10)
    }
  },
  computed: {},
  methods: {
    filter_by_date () {
      this.$api.cameras_accesses(this.start_date, this.end_date)
        .then((response) => {
          this.users_camera_accesses = response.data
        })
        .catch((error) => {
          parent.apiError = false
          if (error.response.status === 403) {
            this.$store.commit('token', null)
            this.$router.push('/login').catch(() => {})
          }
        })
    }
  },
  created () {
    this.$api.cameras_accesses()
      .then((response) => {
        this.users_camera_accesses = response.data
      })
      .catch((error) => {
        parent.apiError = false
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login').catch(() => {})
        }
      })
  },
  mounted () {
  },
  destroyed () {
  }
}
</script>
