<template>
  <div class="login">
    <video autoplay muted loop id="backgroundLoginVideo">
      <source src="sealab_ai_background.mp4" type="video/mp4">
    </video>
    <div class="content">
      <div class="banner">
        <BannerComponent ref="banner"></BannerComponent>
      </div>
      <div class="login_card_div">
        <div class="login_card">
          <div class="login_card_title">Iniciar sesión</div>
          <div class="login_form_label" id="label_email">Correo electrónico</div>
          <input
            v-model="user"
            class="login_input"
            @focus="inputFocus('label_email', true)"
            @blur="inputFocus('label_email', false)"
          >
          <div class="login_form_label" id="label_password">Contraseña</div>
          <div class="login_input_password">
            <input
            v-bind:type="[showPassword ? 'text' : 'password']"
            v-model="password"
            class="login_input"
            @focus="inputFocus('label_password', true)"
            @blur="inputFocus('label_password', false)"
            >
            <div class="login_input_password_toggle">
              <span @click="showPassword = !showPassword">
                <v-img v-if="showPassword" src="img/icons/eye-slash-solid.svg" />
                <v-img class="eye_solid" v-else src="img/icons/eye-solid.svg" />
              </span>
            </div>
          </div>
          <div class="div_recovery_pass_link">
            <a :href="recovery_pass_url">¿Has olvidado tu contraseña?</a>
          </div>
          <div class="menu-button" @click="login()" :class="{disableBtn: loginDisable()}">
            Iniciar sesión
          </div>
          <div class="wrongAuth_div">{{ wrongAuth }}</div>
          <div class="div_contacto">
            <div class="div_contacto_title">Contacto</div>
            <div>
              <div class="whatsapp_div">
                <a href="https://wa.me/34986042830" target="_blank">
                  <img id="whatsapp-icon" src="img/icons/whatsapp.svg" />
                  <div>986 042 830</div>
                </a>
              </div>
              <div>
                <a href="mailto:dev@sealabsolutions.com" target="_blank">
                  dev@sealabsolutions.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="div_copyright">
        {{ new Date().getFullYear() }}
        COPYRIGHT © SEALAB
      </div>
    </div>
  </div>
</template>

<script>
import BannerComponent from '../components/BannerComponent.vue'

export default {
  components: {
    BannerComponent: BannerComponent
  },
  name: 'loginScreen',
  data () {
    return {
      user: '',
      password: '',
      wrongAuth: '',
      showPassword: false
    }
  },
  computed: {
    recovery_pass_url () {
      return process.env.VUE_APP_WEB_URL + 'accounts/password_reset/'
    }
  },
  methods: {
    login () {
      if (!this.loginDisable()) {
        this.$api
          .login(this.user, this.password)
          .then((response) => {
            this.$router.push('/zones').catch(() => {})
          })
          .catch((error) => {
            this.wrongAuth = 'Credenciales incorrectas'
            console.log('ERROR ' + error)
          })
      }
    },
    loginDisable () {
      if (this.user === '' || this.password === '') {
        return true
      } else {
        return false
      }
    },
    inputFocus (labelId, isFocus) {
      let label = document.getElementById(labelId)
      if (isFocus) {
        label.classList.add('labelFocus')
      } else {
        label.classList.remove('labelFocus')
      }
    }
  },
  created () {}
}
</script>
