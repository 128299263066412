<template>
  <div class="cams content" id="visorCams">
    <div class="zone_top_div">
      <span class="zone_name">{{ name }}</span>
      <span class="zone_time">
        <img id="whatsapp-icon" src="img/icons/clock_solid.svg" />
        {{ localTimeFixedString }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'CamScreen',
  data () {
    return {
      intervalFullScreen: null,
      timeoutMouseMovement: null,
      mainCamNormalWidth: '',
      mainCamNormalHeight: '',
      mainCamHoverNormalWidth: '',
      mainCamHoverNormalHeight: '',
      mainCamHoverNormalBottom: '',
      switchFullScreen: true,
      overMainCam: false,
      localTimeFixed: new Date(),
      localTimeFixedString: '',
      intervalLocalTime: null,
      controlActive: false,
      // intervalSendControlsMovement: null,
      // currentSpeedIntervalSendControlsMovementX: 1000,
      // currentSpeedIntervalSendControlsMovementY: 1000,
      // currentSpeedIntervalSendControlsMovementZ: 1000,
      timeoutMainCamInactive: null,
      cacheMainCamUrl: '',
      timeoutCheckCamSlash: null,
      camBoxFilterNormalWidth: '',
      camBoxFilterNormalHeight: '',
      camBoxFilterNormalBottom: '',
      mainCamHoverondblclickEnable: true
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    localTime: {
      type: String,
      required: true
    },
    cams: {
      type: [],
      required: true
    }
  },
  watch: {},
  computed: {},
  methods: {
    setDisplay () {
      let firstLoadPtzSupport = false
      let parent = this
      let mainBoxCams = document.getElementById('visorCams')
      let camsList = document.createElement('div')
      camsList.classList.add('cams_list')
      let mainCamDiv = document.createElement('div')
      mainCamDiv.classList.add('main_cam')
      mainCamDiv.setAttribute('id', 'mainCamDiv')
      for (let cameraIndex in this.cams) {
        let camGroup = document.createElement('div')
        let cam = document.createElement('img')
        let camLabel = document.createElement('div')
        let labelName = document.createElement('span')

        camGroup.classList.add('cam_group')

        camLabel.classList.add('cam_label')
        labelName.classList.add('cam_name')
        labelName.innerHTML = this.cams[cameraIndex].name

        cam.classList.add('camera')
        cam.setAttribute('id', 'cam' + (parseInt(cameraIndex) + 1))

        camLabel.appendChild(labelName)
        camGroup.appendChild(camLabel)
        let camBox = document.createElement('div')
        camBox.classList.add('camBox')
        camBox.appendChild(cam)
        this.configureSrc(cam, parent.cams[cameraIndex].url + '&thumbnail=true')

        if (!parent.cams[cameraIndex].thumbnail_status) {
          let listItemNotCam = document.createElement('div')
          listItemNotCam.classList.add('divlistItemNotCam')
          let listItemNotCamImg = document.createElement('img')
          listItemNotCamImg.src = 'img/icons/cam_slash.svg'
          listItemNotCam.appendChild(listItemNotCamImg)
          let listItemNotCamText = document.createElement('div')
          listItemNotCamText.textContent = 'Cámara no disponible.'
          listItemNotCam.appendChild(listItemNotCamText)
          camBox.appendChild(listItemNotCam)
        }

        camGroup.appendChild(camBox)
        camGroup.addEventListener('click', function (event) {
          parent.changeMainCam(
            parent.cams[cameraIndex].url,
            parent.cams[cameraIndex].ptz_support,
            parent.cams[cameraIndex].pan_timer,
            parent.cams[cameraIndex].tilt_timer,
            parent.cams[cameraIndex].zoom_timer,
            parent.cams[cameraIndex].thumbnail_status,
            parent.cams[cameraIndex].url + '&thumbnail=true'
          )
        })
        camsList.appendChild(camGroup)

        if (parseInt(cameraIndex) === 0) {
          let mainCamImg = document.createElement('img')
          mainCamImg.classList.add('main_camera')
          mainCamImg.setAttribute('id', 'main_camera')
          let camBox = document.createElement('div')
          camBox.classList.add('camBox')
          camBox.appendChild(mainCamImg)
          this.configureSrc(mainCamImg, this.cams[cameraIndex].url)

          let camBoxFilter = document.createElement('div')
          camBoxFilter.classList.add('camBoxFilter')
          camBox.appendChild(camBoxFilter)
          mainCamDiv.appendChild(camBox)
          cam.classList.add('cam_darken')

          let mainCamHover = document.createElement('div')
          mainCamHover.classList.add('main_camera_hover')
          mainCamHover.setAttribute('id', 'main_camera_hover')
          mainCamHover.ondblclick = function (event) {
            if (parent.mainCamHoverondblclickEnable) {
              if (parent.switchFullScreen) {
                if (mainCamDiv.requestFullscreen) {
                  mainCamDiv.requestFullscreen()
                } else if (mainCamDiv.webkitRequestFullscreen) { /* Safari */
                  mainCamDiv.webkitRequestFullscreen()
                } else if (mainCamDiv.msRequestFullscreen) { /* IE11 */
                  mainCamDiv.msRequestFullscreen()
                }
              } else {
                if (document.exitFullscreen) {
                  document.exitFullscreen()
                } else if (document.webkitExitFullscreen) { /* Safari */
                  document.webkitExitFullscreen()
                } else if (document.msExitFullscreen) { /* IE11 */
                  document.msExitFullscreen()
                }
              }
            }
          }

          let liveStatus = document.createElement('div')
          liveStatus.classList.add('main_camera_hover_live')
          let liveStatusCircle = document.createElement('div')
          liveStatusCircle.classList.add('main_camera_hover_live_circle')
          liveStatus.appendChild(liveStatusCircle)
          let liveStatusText = document.createElement('div')
          liveStatusText.classList.add('main_camera_hover_live_text')
          liveStatusText.textContent = 'LIVE'
          liveStatus.appendChild(liveStatusText)
          mainCamHover.appendChild(liveStatus)

          let movementControlsTable = document.createElement('table')
          movementControlsTable.classList.add('movement_controls_table')
          movementControlsTable.id = 'box_controls'
          let row1 = document.createElement('tr')
          row1.appendChild(document.createElement('td'))
          let tdTop = document.createElement('td')
          let btnMovementControlsTop = document.createElement('div')
          btnMovementControlsTop.classList.add('btn_movement_controls')
          // btnMovementControlsTop.textContent = '🡡'
          // btnMovementControlsTop.onclick = function () {
          //   parent.changeCamControlsClick(0, 1, 0)
          // }
          btnMovementControlsTop.onmousedown = function () {
            parent.changeCamControls(true, 0, 1, 0)
            parent.changeControlButtonsImg(1)
            setTimeout(() => {
              parent.changeControlButtonsImg(0)
            }, 500)
          }
          btnMovementControlsTop.onmouseup = function () {
          //   // parent.changeCamControls(false, 0, 1, 0)
            parent.changeControlButtonsImg(0)
          }
          btnMovementControlsTop.onmouseleave = function () {
            parent.changeCamControls(false, 0, 1, 0)
            parent.changeControlButtonsImg(0)
          }
          tdTop.appendChild(btnMovementControlsTop)
          row1.appendChild(tdTop)
          row1.appendChild(document.createElement('td'))
          movementControlsTable.appendChild(row1)
          let row2 = document.createElement('tr')
          let tdLeft = document.createElement('td')
          let btnMovementControlsLeft = document.createElement('div')
          btnMovementControlsLeft.classList.add('btn_movement_controls')
          // btnMovementControlsLeft.textContent = '🡠'
          // btnMovementControlsLeft.onclick = function () {
          //   parent.changeCamControlsClick(-1, 0, 0)
          // }
          btnMovementControlsLeft.onmousedown = function () {
            parent.changeCamControls(true, -1, 0, 0)
            parent.changeControlButtonsImg(4)
            setTimeout(() => {
              parent.changeControlButtonsImg(0)
            }, 500)
          }
          btnMovementControlsLeft.onmouseup = function () {
            // parent.changeCamControls(false, -1, 0, 0)
            parent.changeControlButtonsImg(0)
          }
          btnMovementControlsLeft.onmouseleave = function () {
            parent.changeCamControls(false, -1, 0, 0)
            parent.changeControlButtonsImg(0)
          }
          tdLeft.appendChild(btnMovementControlsLeft)
          row2.appendChild(tdLeft)
          row2.appendChild(document.createElement('td'))
          let tdRight = document.createElement('td')
          let btnMovementControlsRight = document.createElement('div')
          btnMovementControlsRight.classList.add('btn_movement_controls')
          // btnMovementControlsRight.textContent = '🡢'
          // btnMovementControlsRight.onclick = function () {
          //   parent.changeCamControlsClick(1, 0, 0)
          // }
          btnMovementControlsRight.onmousedown = function () {
            parent.changeCamControls(true, 1, 0, 0)
            parent.changeControlButtonsImg(2)
            setTimeout(() => {
              parent.changeControlButtonsImg(0)
            }, 500)
          }
          btnMovementControlsRight.onmouseup = function () {
            // parent.changeCamControls(false, 1, 0, 0)
            parent.changeControlButtonsImg(0)
          }
          btnMovementControlsRight.onmouseleave = function () {
            parent.changeCamControls(false, 1, 0, 0)
            parent.changeControlButtonsImg(0)
          }
          tdRight.appendChild(btnMovementControlsRight)
          row2.appendChild(tdRight)
          movementControlsTable.appendChild(row2)
          let row3 = document.createElement('tr')
          row3.appendChild(document.createElement('td'))
          let tdBottom = document.createElement('td')
          let btnMovementControlsBottom = document.createElement('div')
          btnMovementControlsBottom.classList.add('btn_movement_controls')
          // btnMovementControlsBottom.textContent = '🡣'
          // btnMovementControlsBottom.onclick = function () {
          //   parent.changeCamControlsClick(0, -1, 0)
          // }
          btnMovementControlsBottom.onmousedown = function () {
            parent.changeCamControls(true, 0, -1, 0)
            parent.changeControlButtonsImg(3)
            setTimeout(() => {
              parent.changeControlButtonsImg(0)
            }, 500)
          }
          btnMovementControlsBottom.onmouseup = function () {
            // parent.changeCamControls(false, 0, -1, 0)
            parent.changeControlButtonsImg(0)
          }
          btnMovementControlsBottom.onmouseleave = function () {
            parent.changeCamControls(false, 0, -1, 0)
            parent.changeControlButtonsImg(0)
          }
          tdBottom.appendChild(btnMovementControlsBottom)
          row3.appendChild(tdBottom)
          row3.appendChild(document.createElement('td'))
          movementControlsTable.appendChild(row3)
          movementControlsTable.onmouseover = function (event) {
            parent.mainCamHoverondblclickEnable = false
          }
          movementControlsTable.onmouseleave = function (event) {
            parent.mainCamHoverondblclickEnable = true
          }
          mainCamHover.appendChild(movementControlsTable)

          let zoomControlsTable = document.createElement('table')
          zoomControlsTable.classList.add('zoom_controls_table')
          zoomControlsTable.id = 'box_zoom'
          let rowZoom1 = document.createElement('tr')
          // let zoomControlsBox = document.createElement('div')
          // zoomControlsBox.classList.add('zoom_controls_box')
          // zoomControlsBox.id = 'box_zoom'

          let btnZoomDecrement = document.createElement('div')
          btnZoomDecrement.classList.add('btn_zoom_controls')
          // btnZoomDecrement.textContent = '-'
          // btnZoomDecrement.onclick = function () {
          //   parent.changeCamControlsClick(0, 0, -1)
          // }
          btnZoomDecrement.onmousedown = function () {
            parent.changeCamControls(true, 0, 0, -1)
            parent.changeZoomButtonsImg(1)
            setTimeout(() => {
              parent.changeZoomButtonsImg(0)
            }, 500)
          }
          btnZoomDecrement.onmouseup = function () {
            // parent.changeCamControls(false, 0, 0, -1)
            parent.changeZoomButtonsImg(0)
          }
          btnZoomDecrement.onmouseleave = function () {
            parent.changeCamControls(false, 0, 0, -1)
            parent.changeZoomButtonsImg(0)
          }
          let tdDecrement = document.createElement('td')
          tdDecrement.appendChild(btnZoomDecrement)
          rowZoom1.appendChild(tdDecrement)
          // zoomControlsBox.appendChild(btnZoomDecrement)

          let btnZoomIncrement = document.createElement('div')
          btnZoomIncrement.classList.add('btn_zoom_controls')
          // btnZoomIncrement.textContent = '+'
          // btnZoomIncrement.onclick = function () {
          //   parent.changeCamControlsClick(0, 0, 1)
          // }
          btnZoomIncrement.onmousedown = function () {
            parent.changeCamControls(true, 0, 0, 1)
            parent.changeZoomButtonsImg(2)
            setTimeout(() => {
              parent.changeZoomButtonsImg(0)
            }, 500)
          }
          btnZoomIncrement.onmouseup = function () {
            // parent.changeCamControls(false, 0, 0, 1)
            parent.changeZoomButtonsImg(0)
          }
          btnZoomIncrement.onmouseleave = function () {
            parent.changeCamControls(false, 0, 0, 1)
            parent.changeZoomButtonsImg(0)
          }
          let tdIncrement = document.createElement('td')
          tdIncrement.appendChild(btnZoomIncrement)
          rowZoom1.appendChild(tdIncrement)

          // zoomControlsBox.appendChild(btnZoomIncrement)
          zoomControlsTable.appendChild(rowZoom1)
          zoomControlsTable.onmouseover = function (event) {
            parent.mainCamHoverondblclickEnable = false
          }
          zoomControlsTable.onmouseleave = function (event) {
            parent.mainCamHoverondblclickEnable = true
          }
          mainCamHover.appendChild(zoomControlsTable)

          let btnFullScreen = document.createElement('div')
          btnFullScreen.classList.add('main_camera_hover_btn_fullscreen')
          let btnFullScreenImg = document.createElement('img')
          btnFullScreenImg.setAttribute('id', 'main_camera_hover_btn_fullscreen_img')
          btnFullScreenImg.src = 'img/icons/fullscreen.svg'
          btnFullScreen.appendChild(btnFullScreenImg)
          btnFullScreen.onclick = function (event) {
            if (parent.switchFullScreen) {
              if (mainCamDiv.requestFullscreen) {
                mainCamDiv.requestFullscreen()
              } else if (mainCamDiv.webkitRequestFullscreen) { /* Safari */
                mainCamDiv.webkitRequestFullscreen()
              } else if (mainCamDiv.msRequestFullscreen) { /* IE11 */
                mainCamDiv.msRequestFullscreen()
              }
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen()
              } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen()
              } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen()
              }
            }
          }
          mainCamHover.appendChild(btnFullScreen)

          let zoneNameHover = document.createElement('div')
          zoneNameHover.classList.add('main_camera_hover_zone_name')
          zoneNameHover.setAttribute('id', 'zoneNameHover')
          zoneNameHover.innerHTML = this.name
          mainCamHover.appendChild(zoneNameHover)

          let zoneTimeHover = document.createElement('div')
          zoneTimeHover.classList.add('main_camera_hover_zone_time')
          zoneTimeHover.setAttribute('id', 'zoneTimeHover')
          let zoneTimeHoverImg = document.createElement('img')
          zoneTimeHoverImg.src = 'img/icons/clock_solid.svg'
          zoneTimeHover.appendChild(zoneTimeHoverImg)
          let zoneTimeHoverText = document.createElement('span')
          zoneTimeHoverText.setAttribute('id', 'zoneTimeHoverText')
          zoneTimeHover.appendChild(zoneTimeHoverText)
          mainCamHover.appendChild(zoneTimeHover)

          mainCamDiv.appendChild(mainCamHover)
          // mainCamDiv.onmouseover = function (event) {
          //   parent.overMainCam = true
          //   document.onmousemove = function (event) {
          //     if (parent.overMainCam) {
          //       mainCamHover.style.display = 'block'
          //       mainCamDiv.style.cursor = 'default'
          //       parent.resetTimeoutMouseMovement()
          //     } else {
          //       mainCamHover.style.display = 'none'
          //       mainCamDiv.style.cursor = 'default'
          //     }
          //   }
          // }
          // mainCamDiv.onmouseleave = function (event) {
          //   mainCamHover.style.display = 'none'
          //   mainCamDiv.style.cursor = 'default'
          //   parent.overMainCam = false
          // }

          firstLoadPtzSupport = this.cams[cameraIndex].ptz_support
          // this.currentSpeedIntervalSendControlsMovementX = this.formatControlsSpeed(this.cams[cameraIndex].pan_timer)
          // this.currentSpeedIntervalSendControlsMovementY = this.formatControlsSpeed(this.cams[cameraIndex].tilt_timer)
          // this.currentSpeedIntervalSendControlsMovementZ = this.formatControlsSpeed(this.cams[cameraIndex].zoom_timer)
        }
      }
      mainBoxCams.appendChild(camsList)
      mainBoxCams.appendChild(mainCamDiv)

      this.showPtzControls(firstLoadPtzSupport)

      this.checkCamSlash(parent.cams[0].thumbnail_status)
      this.changeMainCamBackground(this.cams[0].url + '&thumbnail=true')
    },
    changeMainCam (url, ptzSupport, xSpeed, ySpeed, zSpeed, thumbnailStatus, thumbnail) {
      let mainCam = document.getElementById('main_camera')
      this.configureSrc(mainCam, '')
      this.configureSrc(mainCam, url)
      this.changeCamsListOpacity(mainCam.src)

      this.showPtzControls(ptzSupport)

      // this.currentSpeedIntervalSendControlsMovementX = this.formatControlsSpeed(xSpeed)
      // this.currentSpeedIntervalSendControlsMovementY = this.formatControlsSpeed(ySpeed)
      // this.currentSpeedIntervalSendControlsMovementZ = this.formatControlsSpeed(zSpeed)

      this.checkCamSlash(thumbnailStatus)
      this.changeMainCamBackground(thumbnail)
    },
    changeMainCamBackground (img) {
      let camBox = document.getElementById('main_camera').parentNode
      camBox.style.backgroundImage = "url('" + img + "')"
    },
    checkCamSlash (thumbnailStatus) {
      let camBox = document.getElementById('main_camera').parentNode

      let existentNotCams = camBox.querySelectorAll('.divNotCam')
      existentNotCams.forEach(function (existentNotCam) {
        existentNotCam.remove()
      })

      let notCam = document.createElement('div')
      notCam.classList.add('divNotCam')
      let notCamImg = document.createElement('img')
      notCamImg.src = 'img/icons/cam_slash.svg'
      notCam.appendChild(notCamImg)
      let notCamText = document.createElement('div')
      notCamText.textContent = 'Cámara no disponible.'
      notCam.appendChild(notCamText)

      if (thumbnailStatus) {
        let loadingCam = document.createElement('div')
        loadingCam.classList.add('divNotCam')
        let loadingCamLoader = document.createElement('div')
        loadingCamLoader.classList.add('loadingCamLoader')
        loadingCam.appendChild(loadingCamLoader)
        camBox.appendChild(loadingCam)
        clearTimeout(this.timeoutCheckCamSlash)
        this.timeoutCheckCamSlash = setTimeout(
          function () {
            loadingCam.remove()
            camBox.appendChild(notCam)
          }, 15000
        )
      } else {
        camBox.appendChild(notCam)
      }
    },
    showPtzControls (show) {
      let movControls = document.getElementById('box_controls')
      let zoomControls = document.getElementById('box_zoom')
      if (movControls) {
        movControls.style.display = show ? 'block' : 'none'
      }
      if (zoomControls) {
        zoomControls.style.display = show ? 'block' : 'none'
      }
    },
    changeCamsListOpacity (url) {
      let cams = document.getElementsByClassName('camera')
      for (let camsIndex in cams) {
        if (cams[camsIndex].localName === 'img') {
          cams[camsIndex].classList.remove('cam_darken')
          if (cams[camsIndex].src.replace('&thumbnail=true', '') === url) {
            cams[camsIndex].classList.add('cam_darken')
          }
        }
      }
    },
    configureSrc (img, url) {
      img.src = url
      img.parentNode.style.backgroundImage = "url('img/placeholder.png')"
    },
    resetTimeoutMouseMovement () {
      // let mainCamHover = document.getElementById('main_camera_hover')
      // let mainCamDiv = document.getElementById('mainCamDiv')
      // clearTimeout(this.timeoutMouseMovement)
      // this.timeoutMouseMovement = setTimeout(
      //   function () {
      //     mainCamHover.style.display = 'none'
      //     mainCamDiv.style.cursor = 'none'
      //   }, 3000
      // )
    },
    resetTimeoutMainCamInactive () {
      let mainCameraImg = document.getElementById('main_camera')
      let mainCameraImgLastSplited = (
        mainCameraImg.src.split('/')[mainCameraImg.src.split('/').length - 1]
      )
      if (mainCameraImgLastSplited === 'placeholder.png') {
        mainCameraImg.src = this.cacheMainCamUrl
      }
      clearTimeout(this.timeoutMainCamInactive)
      this.timeoutMainCamInactive = setTimeout(
        function () {
          this.cacheMainCamUrl = mainCameraImg.src
          mainCameraImg.src = 'img/placeholder.png'
        }, 600000 // 150000 // 600000 // 10000
      )
    },
    async changeCamControlsClick (x, y, z) {
      const jsonData = {}
      let mainCam = document.getElementById('main_camera')
      let camId = mainCam.src.split('camera_id=')[1].split('&token=')[0]
      jsonData['cam_id'] = camId
      jsonData['x'] = x
      jsonData['y'] = y
      jsonData['z'] = z
      await this.$api.set_cam_controls(jsonData)
        .then((response) => {
          // console.log(response.data)
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.commit('token', null)
            this.$router.push('/login').catch(() => {})
          }
        })
    },
    changeControlButtonsImg (position) {
      let controlsTable = document.getElementsByClassName('movement_controls_table')[0]
      switch (position) {
        case 0:
          controlsTable.style.backgroundImage = "url('/img/icons/camera_dpad.svg')"
          break
        case 1:
          controlsTable.style.backgroundImage = "url('/img/icons/camera_dpad_top.svg')"
          break
        case 2:
          controlsTable.style.backgroundImage = "url('/img/icons/camera_dpad_right.svg')"
          break
        case 3:
          controlsTable.style.backgroundImage = "url('/img/icons/camera_dpad_bottom.svg')"
          break
        case 4:
          controlsTable.style.backgroundImage = "url('/img/icons/camera_dpad_left.svg')"
          break
        default:
          break
      }
    },
    changeZoomButtonsImg (position) {
      let controlsTable = document.getElementsByClassName('zoom_controls_table')[0]
      switch (position) {
        case 0:
          controlsTable.style.backgroundImage = "url('/img/icons/zoom.svg')"
          break
        case 1:
          controlsTable.style.backgroundImage = "url('/img/icons/zoom_less.svg')"
          break
        case 2:
          controlsTable.style.backgroundImage = "url('/img/icons/zoom_more.svg')"
          break
        default:
          break
      }
    },
    async changeCamControls (controlActive, x, y, z) {
      let parent = this
      const jsonData = {}
      let mainCam = document.getElementById('main_camera')
      let camId = mainCam.src.split('camera_id=')[1].split('&token=')[0]
      jsonData['cam_id'] = camId
      jsonData['x'] = x
      jsonData['y'] = y
      jsonData['z'] = z

      if (controlActive) {
        // let intervalSpeed = 1000
        // if (x !== 0) {
        //   intervalSpeed = parent.currentSpeedIntervalSendControlsMovementX
        // } else if (y !== 0) {
        //   intervalSpeed = parent.currentSpeedIntervalSendControlsMovementY
        // } else if (z !== 0) {
        //   intervalSpeed = parent.currentSpeedIntervalSendControlsMovementZ
        // }

        // parent.intervalSendControlsMovement = setInterval(async function () {
        //   parent.resetTimeoutMouseMovement()
        await parent.$api.set_cam_controls(jsonData)
          .then((response) => {
            // console.log(response.data)
          })
          .catch((error) => {
            if (error.response.status === 403) {
              parent.$store.commit('token', null)
              parent.$router.push('/login').catch(() => {})
            }
          })
        // }, intervalSpeed)
      }
      // else {
      //   clearInterval(parent.intervalSendControlsMovement)
      // }
    },
    formatControlsSpeed (speed) {
      return speed * 1000
    }
  },
  created () {
    if (!this.$store.state.token) {
      this.goRoute('/login')
    }
  },
  mounted () {
    let parent = this
    if (!this.$store.state.token) {
      this.goRoute('/login')
    } else {
      if (this.cams) {
        this.setDisplay()
        let mainCamImg = document.getElementById('main_camera')
        parent.mainCamNormalWidth = mainCamImg.style.width
        parent.mainCamNormalHeight = mainCamImg.style.height
        let mainCamHover = document.getElementById('main_camera_hover')
        parent.mainCamHoverNormalWidth = mainCamHover.style.width
        parent.mainCamHoverNormalHeight = mainCamHover.style.height
        parent.mainCamHoverNormalBottom = mainCamHover.style.bottom
        let zoneNameHover = document.getElementById('zoneNameHover')
        let zoneTimeHover = document.getElementById('zoneTimeHover')
        let liveStatus = document.getElementsByClassName('main_camera_hover_live')[0]
        let camBoxFilter = document.getElementsByClassName('camBoxFilter')[0]
        parent.camBoxFilterNormalWidth = camBoxFilter.style.width
        parent.camBoxFilterNormalHeight = camBoxFilter.style.height
        parent.camBoxFilterNormalBottom = camBoxFilter.style.bottom
        this.intervalFullScreen = setInterval(function () {
          let mainCamDiv = document.getElementById('mainCamDiv')
          let btnImg = document.getElementById('main_camera_hover_btn_fullscreen_img')
          if (document.fullscreenElement || document.webkitFullscreenElement ||
          document.mozFullScreenElement) {
            mainCamDiv.style.width = '100vw'
            mainCamImg.style.width = '100vw'
            mainCamImg.style.height = '100vh'
            mainCamImg.parentNode.style.width = '100vw'
            mainCamImg.parentNode.style.height = '100vh'
            mainCamHover.classList.add('mainCamHoverFullScreen')
            mainCamHover.style.width = '100vw'
            mainCamHover.style.height = '101vh'
            mainCamHover.style.bottom = '101vh'
            parent.switchFullScreen = false
            btnImg.src = 'img/icons/exit_fullscreen.svg'
            zoneNameHover.style.display = 'block'
            zoneTimeHover.style.display = 'block'
            liveStatus.style.marginRight = '8vw'
            camBoxFilter.style.width = '100vw'
            camBoxFilter.style.height = '100vh'
            camBoxFilter.style.bottom = '0'
          } else {
            mainCamDiv.style.width = 'unset'
            mainCamImg.style.width = parent.mainCamNormalWidth
            mainCamImg.style.height = parent.mainCamNormalHeight
            mainCamImg.parentNode.style.width = parent.mainCamNormalWidth
            mainCamImg.parentNode.style.height = parent.mainCamNormalHeight
            mainCamHover.classList.remove('mainCamHoverFullScreen')
            mainCamHover.style.width = parent.mainCamHoverNormalWidth
            mainCamHover.style.height = parent.mainCamHoverNormalHeight
            mainCamHover.style.bottom = parent.mainCamHoverNormalBottom
            parent.switchFullScreen = true
            btnImg.src = 'img/icons/fullscreen.svg'
            zoneNameHover.style.display = 'none'
            zoneTimeHover.style.display = 'none'
            liveStatus.style.marginRight = '1vw'
            camBoxFilter.style.width = parent.camBoxFilterNormalWidth
            camBoxFilter.style.height = parent.camBoxFilterNormalHeight
            camBoxFilter.style.bottom = parent.camBoxFilterNormalBottom
          }
        }, 100)
        this.localTimeFixed = new Date()
        this.localTimeFixed.setHours(parseInt(this.localTime.slice(0, 3)))
        this.localTimeFixed.setMinutes(parseInt(this.localTime.slice(3, 5)))
        this.localTimeFixedString = (
          ('0' + this.localTimeFixed.getHours().toString()).slice(-2) + ':' +
          ('0' + this.localTimeFixed.getMinutes().toString()).slice(-2)
        )
        let zoneTimeHoverText = document.getElementById('zoneTimeHoverText')
        zoneTimeHoverText.textContent = this.localTimeFixedString
        this.intervalLocalTime = setInterval(function () {
          parent.localTimeFixed.setSeconds(parent.localTimeFixed.getSeconds() + 1)
          parent.localTimeFixedString = (
            ('0' + parent.localTimeFixed.getHours().toString()).slice(-2) + ':' +
            ('0' + parent.localTimeFixed.getMinutes().toString()).slice(-2)
          )
          zoneTimeHoverText.textContent = (
            ('0' + parent.localTimeFixed.getHours().toString()).slice(-2) + ':' +
            ('0' + parent.localTimeFixed.getMinutes().toString()).slice(-2)
          )
        }, 1000)
        let appElement = document.getElementById('app')
        appElement.onmousemove = function (event) {
          parent.resetTimeoutMainCamInactive()
        }
      } else {
        this.$router.push('/zones').catch(() => {})
      }
    }
  },
  destroyed () {
    document.getElementById('app').onmousemove = null
    clearInterval(this.intervalFullScreen)
    clearInterval(this.intervalLocalTime)
  }
}
</script>
