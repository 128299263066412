import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    token: null,
    zones: []
  },
  mutations: {
    loading (state, payload) {
      state.loading = payload
    },
    token (state, payload) {
      state.token = payload
    }
  },
  actions: {},
  getters: {
    authenticated: state => {
      return state.token != null
    }
  },
  plugins: [createPersistedState({
    key: process.env.VUE_APP_VUEX_KEY
  })]
})
