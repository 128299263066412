<template>
  <div @click="goRoutes('zones')">
    <svg version="1.1" id="banner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 777 179.2" style="enable-background:new 0 0 777 179.2;" xml:space="preserve">
      <path style="fill:#FFFFFF;" id="square_3_" class="st0" d="M552.1,173.3v-23.5c0.5-0.1,1-0.1,1.5-0.1c6.7,0,13.4,0.1,20-0.1c1.5,0,1.8,0.5,1.7,1.8
      c-0.1,6.8-0.1,13.6,0,20.4c0,0.9,0.1,1.7-1.3,1.7c-7.1-0.1-14.2-0.1-21.3-0.1C552.5,173.4,552.3,173.4,552.1,173.3z"/>
      <g id="Logo_7_">
      <path style="fill:#FFFFFF;" id="external_square_3_" class="st0" d="M602.6,5.5v167.8h167.8V5.5H602.6z M756.2,159.1H617V19.8h139.2V159.1z"/>
      <polygon style="fill:#FFFFFF;" class="st0" points="733.7,28.4 737.8,38.2 747.7,42.3 737.8,46.5 733.7,56.3 729.6,46.5 719.7,42.3 729.6,38.2"/>
      <path style="fill:#FFFFFF;" class="st0" d="M687.3,42.3c-25.6,0-46.3,20.7-46.3,46.3s20.7,46.3,46.3,46.3s46.3-20.7,46.3-46.3S712.9,42.3,687.3,42.3z
        M687.3,121c-17.9,0-32.4-14.5-32.4-32.4s14.5-32.4,32.4-32.4s32.4,14.5,32.4,32.4S705.2,121,687.3,121z"/>
      </g>
      <g id="logo_aburrido_2_">
      <g id="text_3_" transform="translate(0.017)">
        <path id="b_1_" style="fill:#FFFFFF;" class="st0" d="M434.1,55.1h20.7v41.1c0.1,0.7,0.3,1.3,0.5,1.9c0.6-0.3,1.1-0.7,1.6-1.2
        c7.6-9.8,17.8-13.7,29.8-12.3c13.5,1.6,24.3,8.2,31.8,19.6c3.7,5.7,5.4,12,6.2,18.6c1,8.8,0.4,17.4-2.9,25.8
        c-5.6,14.3-16.2,22.7-31.1,26c-13.4,3-24.6-0.9-33.5-11.3c-0.5-0.7-1.1-1.2-1.6-1.8h-0.4v8.9c0,3.2,0,3.2-3.2,3.2H434
        C434.1,134,434.1,94.6,434.1,55.1z M504.4,129.4c-0.2-2.2-0.5-4.3-0.9-6.5c-2.3-9.8-8.2-16.6-18.2-18.5c-10.3-2-19.5,0.7-25.9,9.4
        c-4.5,6.1-5.5,13.1-4.5,20.5c2.8,20.3,22.3,26,36.1,19.3C500.5,148.9,504,140.5,504.4,129.4z"/>
        <path id="a_1_" style="fill:#FFFFFF;" class="st0" d="M415.2,173.6h-20.8v-12c-0.8,0.4-1.4,0.5-1.7,0.9c-9.1,13-26.3,15.9-40.8,10.2
        c-16.2-6.4-25-18.7-27.3-35.8c-1.3-8.8-0.3-17.8,3-26.1c5.6-14,16.2-22.1,30.8-25.4c5.5-1.4,11.2-1.5,16.7-0.3
        c6.9,1.6,12.3,5.5,16.6,11.1c0.6,0.8,1.4,1.6,2.3,2.1V86.7h21.2C415.2,115.6,415.2,144.6,415.2,173.6z M395.1,129.9
        c-0.3-2.7-0.8-5.4-1.4-8c-2.7-9.4-8.9-15.5-18.5-17.6c-9.5-2-17.9,0.2-24.3,7.7c-5.2,6.1-6.6,13.4-5.8,21.2
        c1.9,19.2,18.7,25.9,32.5,21.7C388.2,151.9,394.9,142.1,395.1,129.9L395.1,129.9z"/>
        <path id="l_1_" style="fill:#FFFFFF;" class="st0" d="M310.3,55.1v118.7h-20.6V55.1H310.3z"/>
        <path id="a-2_1_" style="fill:#FFFFFF;" class="st0" d="M249.8,98.1V86.6h21.1v86.9h-20.8v-11.9c-0.8,0.5-1.4,0.7-1.6,1c-8.9,12.7-25.4,15.8-39.8,10.4
        c-16.6-6.1-25.8-18.4-28.1-35.6c-1.3-9.4-0.5-18.6,3.3-27.4c5.8-13.5,16.3-21.3,30.5-24.5c7.2-1.6,14.4-1.7,21.4,1.4
        c4.8,2.2,9,5.5,12.2,9.8c0.5,0.6,1.1,1.1,1.6,1.7C249.5,98.2,249.7,98.2,249.8,98.1z M225.6,103.8c-11.3,0-20,6.1-23.4,16.4
        c-1.8,5.3-2.1,11.1-0.8,16.6c2.2,9.6,7.8,16.5,17.7,18.7c10.4,2.3,19.5-0.2,26.3-8.8c4.6-5.8,5.7-12.6,5.1-19.9
        C249.3,113.4,239,103.8,225.6,103.8L225.6,103.8z"/>
        <path id="e_1_" style="fill:#FFFFFF;" class="st0" d="M170.1,137.9h-63.5c0,3,0.9,5.9,2.6,8.3c4,6.1,9.9,9.3,17,10.5c9.1,1.6,17.6-0.7,25.5-5.2
        c1.4-0.8,2.1-0.8,3.2,0.5c3,3.6,6.3,7,9.5,10.5c-5.7,6.3-12.8,9.9-20.9,11.6c-10.9,2.3-21.7,2.3-31.9-2.3
        c-13.5-6.1-23.1-15.8-25.9-30.6c-2.8-15.1-0.8-29.6,9.7-41.5c14.8-16.7,41.9-21.9,61.5-4.7c8.3,7.3,12.4,16.8,13.6,27.6
        c0.3,2.5,0.4,5.1,0.3,7.6C170.7,132.7,170.4,135.2,170.1,137.9z M107.1,121.8h43.6c-0.9-8.3-9.6-20.3-24.5-18.7
        C116.4,104.2,106.1,114.1,107.1,121.8L107.1,121.8z"/>
        <path id="s_1_" style="fill:#FFFFFF;" class="st0" d="M25.8,146.2c1.8,2.4,3.4,4.8,5.3,6.9c5.3,5.7,14,6.8,20.5,2.6c3.2-2.2,4.6-5.6,3.5-9.1
        c-0.8-2.9-3-4.5-5.6-5.5c-5.1-2-10.2-3.9-15.1-6.1c-3.8-1.6-7.5-3.5-10.9-5.8C8,118.5,9.1,97.2,25.9,88.5
        c12.2-6.3,24.6-6.2,36.1,2.2c5.1,3.7,8.4,8.7,10.7,14.5c0.1,0.4,0.2,0.7,0.2,1.1c-5.2,1.4-10.3,2.8-15.4,4c-0.9,0-1.7-0.3-2.3-0.9
        c-0.9-0.9-1.7-1.9-2.4-3c-3.6-4.4-9.8-5.8-14.9-3.4c-2.5,1.1-4.2,3.5-4.3,6.2c-0.5,2.7,0.8,5.4,3.2,6.8c1.8,1,3.8,2,5.7,2.8
        c6.9,2.9,13.9,5.7,20.7,8.7c18.9,8.4,15.2,33.6,2.7,41.6c-7.2,4.5-15.5,6.7-24,6.4c-7.4,0-14.7-2.2-20.9-6.4
        c-5.8-4-8.9-9.8-11.3-16.1c-0.3-0.9-0.5-1.8,0.9-2.2C15.6,149.3,20.7,147.7,25.8,146.2z"/>
      </g>
      <rect style="fill:none;" x="11.4" y="36.3" class="st1" width="226.9" height="60.6"/>
      <text transform="matrix(1 0 0 1 11.3717 73.7853)" style="fill:#FFFFFF; font-family:'Urbanist-Bold'; font-size:50px;" class="st0 st2 st3">livecams</text>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'banner-component',
  methods: {
    goRoutes (route) {
      if (this.$route.name !== 'login') {
        this.$router.push(route).catch(() => {})
      }
    }
  }
}
</script>
