<template>
  <div class="zones content" id="visorZones">
    <h1 :hidden="!apiError">Tus localizaciones</h1>
    <div class="api_error_div" :hidden="apiError">Ha ocurrido un error inesperado</div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'ZonesScreen',
  data () {
    return {
      zones: [],
      apiError: true
    }
  },
  computed: {},
  methods: {
    setDisplay () {
      let parent = this
      let mainBoxZones = document.getElementById('visorZones')

      for (let zoneIndex in this.zones) {
        let zoneName = (
          this.zones[zoneIndex].zoneName + ', ' +
          this.zones[zoneIndex].zoneCountry
        )
        let zoneGroup = document.createElement('div')
        zoneGroup.classList.add('zone_group')

        let zoneLabel = document.createElement('div')
        zoneLabel.classList.add('zone_label')
        let labelName = document.createElement('span')
        labelName.classList.add('zone_name')
        labelName.innerHTML = zoneName

        let zone = document.createElement('img')
        zone.classList.add('zone')
        zone.setAttribute('id', 'zone' + (parseInt(zoneIndex) + 1))
        zone.addEventListener('click', this.goToCams)

        let zoneCams = this.zones[zoneIndex].zoneCams

        zoneGroup.zoneCams = zoneCams
        zoneGroup.zoneName = zoneName
        zoneGroup.zoneTime = this.zones[zoneIndex].zoneTime

        if (this.zones.length === 1) {
          zoneGroup.click()
        }

        let zoneBox = document.createElement('div')
        zoneBox.classList.add('zoneBox')
        zoneBox.appendChild(zone)
        this.changeTumbnail(0, zoneCams, zone)
        zoneGroup.appendChild(zoneBox)
        zoneLabel.appendChild(labelName)
        zoneGroup.appendChild(zoneLabel)

        let circlesTumbnailBox = []
        for (let camIndex in zoneCams) {
          let circle = document.createElement('div')
          circle.classList.add('circleTumbnail')
          if (parseInt(camIndex) === 0) {
            circle.classList.add('circleTumbnailActive')
          }
          let circleBox = document.createElement('div')
          circleBox.addEventListener('click', function (event) {
            for (let circlesTumbnailBoxIndex in circlesTumbnailBox) {
              circlesTumbnailBox[circlesTumbnailBoxIndex].childNodes[0]
                .classList.remove('circleTumbnailActive')
            }
            if (event.target.classList.contains('circleTumbnail')) {
              event.target.classList.add('circleTumbnailActive')
            } else {
              event.target.childNodes[0].classList.add('circleTumbnailActive')
            }

            parent.changeTumbnail(camIndex, zoneCams, zone)
          })
          circleBox.classList.add('circleBox')
          circleBox.appendChild(circle)
          circlesTumbnailBox.push(circleBox)
        }
        let circlesTumbnailGroup = document.createElement('div')
        circlesTumbnailGroup.classList.add('circlesTumbnailGroup')
        for (let circlesTumbnailIndex in circlesTumbnailBox) {
          circlesTumbnailGroup.appendChild(circlesTumbnailBox[circlesTumbnailIndex])
        }
        zoneGroup.appendChild(circlesTumbnailGroup)

        mainBoxZones.appendChild(zoneGroup)
      }
    },
    goToCams (evt) {
      this.$router.push({
        name: 'cams',
        params: {
          name: evt.currentTarget.parentNode.parentNode.zoneName,
          localTime: evt.currentTarget.parentNode.parentNode.zoneTime,
          cams: evt.currentTarget.parentNode.parentNode.zoneCams
        }
      }).catch(() => {})
    },
    changeTumbnail (index, zoneCams, img) {
      img.src = zoneCams[index].url + '&thumbnail=true'
      img.parentNode.style.backgroundImage = "url('img/placeholder.png')"

      let existentNotCam = img.parentNode.childNodes[1]
      if (existentNotCam) {
        existentNotCam.remove()
      }

      if (!zoneCams[index].thumbnail_status) {
        let zoneBox = img.parentNode
        let listItemNotCam = document.createElement('div')
        listItemNotCam.classList.add('divNotCam')
        let listItemNotCamImg = document.createElement('img')
        listItemNotCamImg.src = 'img/icons/cam_slash.svg'
        listItemNotCam.appendChild(listItemNotCamImg)
        let listItemNotCamText = document.createElement('div')
        listItemNotCamText.textContent = 'Cámara no disponible.'
        listItemNotCam.appendChild(listItemNotCamText)
        zoneBox.appendChild(listItemNotCam)
      }
    }
  },
  created () {},
  mounted () {
    var parent = this
    if (!this.$store.state.token) {
      this.goRoute('/login')
    } else {
      this.$api.zones()
        .then((response) => {
          response.data.results.forEach(function (value) {
            for (var cam in value.cams) {
              value.cams[cam].url = (
                value.cams[cam].url +
                '&token=' + parent.$store.state.token
              ).replace('https://http://', 'http://')
            }
            parent.zones.push({
              zoneName: value.name,
              zoneCountry: value.country,
              zoneTime: value.local_time,
              zoneCams: value.cams
            })
          })
          if (parent.zones.length === 1) {
            this.$router.push({
              name: 'cams',
              params: {
                name: parent.zones[0].zoneName,
                localTime: parent.zones[0].zoneTime,
                cams: parent.zones[0].zoneCams
              }
            }).catch(() => {})
          }
          this.setDisplay()
        })
        .catch((error) => {
          parent.apiError = false
          if (error.response.status === 403) {
            this.$store.commit('token', null)
            this.$router.push('/login').catch(() => {})
          }
        })
    }
  },
  destroyed () {
  }
}
</script>
