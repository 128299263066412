import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './services/api'
import './assets/scss/app.scss'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
// import colors from 'vuetify/es5/util/colors'
// import numeral from 'numeral'
import 'vue-animate-transitions/dist/vue-animate-transitions.min.css'
import 'material-design-icons/index.js'
// import VueGtag from 'vue-gtag'

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

Vue.use(Api, { store })
Vue.use(Vuetify, {
  theme: { disable: true }
})
Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
