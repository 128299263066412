<template>
  <div id="app">
    <v-app :class="`route-${this.$router.currentRoute.name}`">
      <link
        rel="shortcut icon"
        type="image/png"
        href="favicon.png"
      />

      <!-- <loading-component></loading-component> -->

      <div class="topbar" :hidden="this.$route.name === 'login' ? true : false">
        <BannerComponent ref="banner"></BannerComponent>
        <div class="div-btn-close-session">
          <v-btn class="btn-close-session" @click="closeSessionBtnClick">
            <img v-if="this.$route.name === 'cams'" class="back-icon" src="img/icons/arrow_back.svg" />
            <img v-else class="return-icon" src="img/icons/close_sesion.svg" />
            <span v-if="this.$route.name === 'cams'">Volver</span>
            <span v-else>Cerrar sesión</span>
          </v-btn>
        </div>
      </div>

      <router-view />

      <div class="footer" :hidden="this.$route.name === 'login' ? true : false">
        <div class="div_logo">
          <a class="sealab_logo" href="https://sealabsolutions.com/" target="_blank">
            <div class="div_logo_copy">
              {{ new Date().getFullYear() }}
              COPYRIGHT © SEALAB
            </div>
          </a>
        </div>
        <div class="div_contacto">
          <div class="div_contacto_box">
            <table>
              <tr>
                <td>
                  <a class="whatsapp_box" href="https://wa.me/34986042830" target="_blank">
                    <tr>
                      <td><img id="whatsapp-icon" src="img/icons/whatsapp.svg" /></td>
                      <td>986 042 830</td>
                    </tr>
                  </a>
                </td>
                <td>
                  <a href="mailto:dev@sealabsolutions.com" target="_blank">
                    dev@sealabsolutions.com
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="div_legal">
          <div>
            <a @click="goRoutes('terms-use')" target="_blank">
              Condiciones de uso
            </a>
          </div>
          <div>
            <a @click="goRoutes('privacy-policy')" target="_blank">
              Política de privacidad
            </a>
          </div>
        </div>
      </div>

    </v-app>
  </div>
</template>

<script>
// import LoadingComponent from './components/LoadingComponent.vue'
import BannerComponent from './components/BannerComponent.vue'

export default {
  components: {
    // LoadingComponent,
    BannerComponent: BannerComponent
  },
  data: function () {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    closeSessionBtnClick () {
      if (this.$route.name === 'cams') {
        this.$router.push('/zones').catch(() => {})
      } else {
        this.$store.commit('token', null)
        this.$router.push('/login').catch(() => {})
      }
    },
    goRoutes (route) {
      this.$router.push(route).catch(() => {})
    }
  },
  created () {
    if (this.$store.state.token) {
      if (!this.$router.currentRoute.name) {
        this.$router.push('/zones').catch(() => {})
      }
    } else {
      this.$router.push('/login').catch(() => {})
    }
  },
  mounted () {
    this.$store.commit('loading', false)
  },
  destroyed () {}
}
</script>
