<template>
  <div class="terms_use">
    <div class="content">
      <div class="info_card">
        <div class="info_card_title">CONDICIONES DE USO</div>
        <div class="info_card_text">
          El presente texto establece los términos en que
          <span class="enterprise_text">Sealab Solutions</span> usa y
          protege la información que es proporcionada por sus usuarios al momento
          de utilizar su sitio web. Esta compañía está comprometida con la seguridad
          de los datos de sus usuarios.
          <br>
          Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser
          actualizada por lo que le recomendamos y enfatizamos revisar continuamente
          esta página para asegurarse que está de acuerdo con dichos cambios.
          <br><br>
          <div class="info_card_text_subtitle">Información que es recogida</div>
          Nuestro sitio web almacena información de contacto como su dirección de correo
          electrónico o direcciones ip de las cámaras disponibles.
          <br><br>
          <div class="info_card_text_subtitle">Uso de la información recogida</div>
          Nuestro sitio web emplea la información con el fin de proporcionar el mejor
          servicio posible para mejorar nuestros productos y servicios.
          <br>
          Es posible que sean enviados correos electrónicos con información
          que consideremos relevante para usted o que pueda brindarle algún
          beneficio, estos correos electrónicos serán enviados a la dirección que usted
          proporcione y podrán ser cancelados en cualquier momento.
          <br><br>
          <span class="enterprise_text">Sealab Solutions</span> está altamente comprometido
          para cumplir con el compromiso de mantener su información segura. Usamos los sistemas
          más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún
          acceso no autorizado.
          <br><br>
          <div class="info_card_text_subtitle">Control de su información personal</div>
          En cualquier momento usted puede restringir la recopilación o el uso de la información
          personal que es proporcionada a nuestro sitio web.
          <br><br>
          Esta compañía no venderá, cederá ni distribuirá la información personal que es
          recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.
          <br><br>
          <span class="enterprise_text">Sealab Solutions</span> Se reserva el derecho de cambiar los
          términos de la presente Política de Privacidad en cualquier momento.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'TermsUseScreen',
  data () {
    return {}
  },
  computed: {},
  methods: {},
  created () {}
}
</script>
