import Vue from 'vue'
import Router from 'vue-router'
import Vuex from 'vuex'
import store from './store'
import Zones from './views/Zones.vue'
import Cams from './views/Cams.vue'
import Login from './views/Login.vue'
import PrivacyPolicy from './views/Privacy_Policy.vue'
import TermsUse from './views/Terms_Use.vue'
import Statistics from './views/Statistics.vue'

Vue.use(Vuex)
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/zones',
      name: 'zones',
      component: Zones
    },
    {
      path: '/cams',
      name: 'cams',
      props: true,
      component: Cams
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      props: true,
      component: PrivacyPolicy
    },
    {
      path: '/terms-use',
      name: 'terms-use',
      props: true,
      component: TermsUse
    },
    {
      path: '/estadisticas',
      name: 'statistics',
      props: true,
      component: Statistics
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters.authenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
